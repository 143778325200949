import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { ANDROID_URL, IOS_URL } from '../helpers/apiSettings';

export default function AppBanner({ onClose }) {
  const [isHidden, setIsHidden] = useState(true);
  const [notInstalled, setNotInstalled] = useState(false);

  // App store URLs - replace with your actual app store URLs
  const appStoreUrl = IOS_URL;
  const playStoreUrl = ANDROID_URL;

  useEffect(() => {
    // Check if banner was previously hidden and when
    const bannerState = localStorage.getItem('appBannerState');
    if (bannerState) {
      const { hidden, timestamp } = JSON.parse(bannerState);
      const hoursSinceHidden = (Date.now() - timestamp) / (1000 * 60 * 60);
      
      // Show banner if 4 hours have passed
      if (hoursSinceHidden >= 4) {
        setIsHidden(false);
        localStorage.removeItem('appBannerState');
      } else {
        setIsHidden(hidden);
      }
    } else {
      setIsHidden(false); // Show banner if no state saved
    }
  }, []);

  const handleClose = () => {
    const today = new Date().toDateString();
    const currentState = JSON.parse(localStorage.getItem('appBannerState') || '{}');
    
    localStorage.setItem('appBannerState', JSON.stringify({
      hidden: true,
      timestamp: Date.now(),
      date: today,
      showCount: (currentState.date === today ? currentState.showCount + 1 : 1)
    }));
    
    setIsHidden(true);
    setNotInstalled(false); // Reset the not installed state
    if (onClose) onClose();
  };

  const openApp = () => {
    // Check if we're on a product page
    const path = window.location.pathname;
    const isProductPage = path.includes('/product/');
    
    let appUrl;
    if (isProductPage) {
      const productId = path.split('/').pop();
      appUrl = `friperiz://product/${productId}`;
    } else {
      appUrl = 'friperiz://'; // Root of your app
    }
  
    // Try to open app
    window.location.href = appUrl;

    // If app doesn't open, show install options after a delay
    const fallbackTimer = setTimeout(() => {
      setNotInstalled(true);
    }, 2000);

    return () => clearTimeout(fallbackTimer);
  };

  const openStore = () => {
    // Detect iOS vs Android and open appropriate store
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    window.location.href = isIOS ? appStoreUrl : playStoreUrl;
    handleClose();
  };

  if (isHidden) return null;

  return (
    <div className="fixed-bottom bg-white border-top shadow-sm p-3">
      <div className="container">
        <div className="row align-items-center">
          <div className="col">
            {!notInstalled ? (
              <>
                <h6 className="mb-0">Ouvrir dans l'application ?</h6>
                <small className="text-muted">
                  Profitez d'une meilleure expérience dans notre application
                </small>
              </>
            ) : (
              <>
                <h6 className="mb-0">Application non installée</h6>
                <small className="text-muted">
                  Installer notre application pour une meilleure expérience
                </small>
              </>
            )}
          </div>
          <div className="col-auto d-flex align-items-center gap-2">
            {!notInstalled ? (
              <button
                onClick={openApp}
                className="btn btn-primary btn-sm"
              >
                Ouvrir
              </button>
            ) : (
              <button
                onClick={openStore}
                className="btn btn-primary btn-sm"
              >
                Installer
              </button>
            )}
            <button
              onClick={handleClose}
              className="btn btn-light btn-sm rounded-circle p-1"
            >
              <X size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}