import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
import { Protector } from "./helpers/userData";
import AppBanner from "./components/AppBanner";

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

// shop page
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const ForgottenPassword = lazy(() => import("./pages/other/ForgottenPassword"));
const DynamicPage = lazy(() => import("./pages/other/DynamicPage"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const ThankYou = lazy(() => import("./pages/other/ThankYouPage"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const Logout = lazy(() => import("./pages/other/Logout"));

const shouldShowBanner = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const App = () => {
  return (
      <Router>
        <ScrollToTop>
          <Suspense>
            <Routes>
              <Route
                path={process.env.PUBLIC_URL + "/"}
                element={<HomeFashion/>}
              />

              {/* Homepage */}
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion"}
                element={<HomeFashion/>}
              />

              {/* Shop page */}
              <Route
                path={process.env.PUBLIC_URL + "/products"}
                element={<ShopGridStandard/>}
              />

              {/* Shop product page */}
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                element={<Product />}
              />

              {/* Other pages */}
              <Route
                path={process.env.PUBLIC_URL + "/page/:pageName"}
                element={<DynamicPage/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/about"}
                element={<About/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                element={<Contact/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/my-account/*"}
                element={<Protector Component={MyAccount} />}
                // element={<MyAccount />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/connexion-inscription"}
                element={<LoginRegister/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/mot-de-passe-oublie"}
                element={<ForgottenPassword/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/logout"}
                element={<Logout/>}
              />

              <Route
                path={process.env.PUBLIC_URL + "/cart"}
                element={<Cart/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/wishlist"}
                element={<Wishlist/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/compare"}
                element={<Compare/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                element={<Protector Component={Checkout} />}
              /> 
              <Route
                path={process.env.PUBLIC_URL + "/thank-you"}
                element={<Protector Component={ThankYou} />}
              /> 

              <Route path="*" element={<NotFound/>} />
            </Routes>
            {/* <ToastContainer /> */}
          </Suspense>
          {shouldShowBanner && <AppBanner />}
        </ScrollToTop>
      </Router>
  );
};

export default App;